<template>
  <div>
    <router-view></router-view>
    <cookie-law class="mytheme">
      <div slot="message">
        This website uses cookies to ensure you get the best experience on our
        website.
        <router-link to="/tos" style="margin-right: 4px"
          >Terms of Service</router-link
        >
        <router-link to="/privacy">Privacy Policy</router-link>
      </div>
    </cookie-law>
  </div>
</template>

<script>
export default {
  name: "Main",
  components: {
    CookieLaw: () => import("vue-cookie-law"),
  },
};
</script>

<style lang="scss">
.Cookie--base {
  background-color: white !important;
  .Cookie__button {
    background-color: #405F9A;
    border-radius: 32px;
  }
  .Cookie__button:hover {
    background-color: #405F9A;
  }
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url("./assets/fonts/Nunito-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoLight";
  src: local("NunitoLight"),
    url("./assets/fonts/Nunito-Light.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoBold";
  src: local("NunitoBold"),
    url("./assets/fonts/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSemiBold";
  src: local("NunitoSemiBold"),
    url("./assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}
</style>