import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Presentation',
    component: () => import('../views/Presentation.vue')
  },
  {
    path: '/tracks/:trackId',
    name: 'Trakcs',
    component: () => import('../views/Tracks.vue')
  },
  {
    path: '/tos',
    name: 'Terms',
    component: () => import('../views/TaC.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // eslint-disable-next-line to ignore the next line
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

export default router
